// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.post__widget {
  height: 450px;
  margin-bottom: 30px;
}
.post__widget-text {
  margin-top: 25px;
  font-size: 19px;
  font-weight: 400;
  color: var(--e-global-color-primary);
}
@media (max-width: 450px) {
  .post__widget-text {
    font-size: 16px;
  }
}
.post__widget-adress {
  font-size: 19px;
  font-weight: 400;
  color: var(--e-global-color-primary);
  margin-right: 10px;
}
@media (max-width: 450px) {
  .post__widget-adress {
    font-size: 16px;
  }
}
.post__widget-reset {
  font-size: 19px;
  font-weight: 400;
  color: #3A5BCC;
  cursor: pointer;
}
@media (max-width: 450px) {
  .post__widget-reset {
    font-size: 16px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Checkout/PostRus/style.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;AACJ;AACI;EACI,gBAAA;EACA,eAAA;EACA,gBAAA;EACA,oCAAA;AACR;AAAQ;EALJ;IAMQ,eAAA;EAGV;AACF;AAAI;EACI,eAAA;EACA,gBAAA;EACA,oCAAA;EACA,kBAAA;AAER;AADQ;EALJ;IAMQ,eAAA;EAIV;AACF;AADI;EACI,eAAA;EACA,gBAAA;EACA,cAAA;EACA,eAAA;AAGR;AAFQ;EALJ;IAMQ,eAAA;EAKV;AACF","sourcesContent":[".post__widget {\n    height: 450px;\n    margin-bottom: 30px;\n\n    &-text {\n        margin-top: 25px;\n        font-size: 19px;\n        font-weight: 400;\n        color: var(--e-global-color-primary);\n        @media(max-width: 450px) {\n            font-size: 16px\n        }\n    }\n\n    &-adress {\n        font-size: 19px;\n        font-weight: 400;\n        color: var(--e-global-color-primary);\n        margin-right: 10px;\n        @media(max-width: 450px) {\n            font-size: 16px\n        }\n    }\n\n    &-reset {\n        font-size: 19px;\n        font-weight: 400;\n        color: #3A5BCC;\n        cursor: pointer;\n        @media(max-width: 450px) {\n            font-size: 16px\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
