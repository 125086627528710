// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dropdown {
  margin-top: 25px;
}
.dropdown__title {
  font-size: 18px;
  font-weight: 500;
  color: rgb(7, 7, 7);
  cursor: pointer;
}
.dropdown__title span {
  font-size: 18px;
  color: #f00505;
  font-weight: 600;
}
.dropdown__modal {
  margin-top: 3px;
  position: absolute;
  width: 200px;
  height: auto;
  background-color: #ffffff;
  border-radius: 10px;
  border: 2px solid rgb(156, 153, 153);
  cursor: pointer;
}
.dropdown__modal-content {
  padding-top: 10px;
  padding-left: 9px;
  float: left;
}
.dropdown__modal-items {
  list-style-type: none;
}
.dropdown__modal-item {
  font-size: 18px;
  font-weight: 400;
  color: rgb(7, 6, 6);
  padding-bottom: 3px;
  cursor: pointer;
}
.dropdown__modal-item:hover {
  font-weight: 600;
}`, "",{"version":3,"sources":["webpack://./src/pages/Admin/AdminOrder/style.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;AACJ;AAAI;EACI,eAAA;EACA,gBAAA;EACA,mBAAA;EACA,eAAA;AAER;AADQ;EACI,eAAA;EACA,cAAA;EACA,gBAAA;AAGZ;AACI;EACI,eAAA;EACA,kBAAA;EACA,YAAA;EACA,YAAA;EACA,yBAAA;EACA,mBAAA;EACA,oCAAA;EACA,eAAA;AACR;AACQ;EACI,iBAAA;EACA,iBAAA;EACA,WAAA;AACZ;AAEQ;EACI,qBAAA;AAAZ;AAGQ;EACI,eAAA;EACA,gBAAA;EACA,mBAAA;EACA,mBAAA;EACA,eAAA;AADZ;AAEY;EACI,gBAAA;AAAhB","sourcesContent":[".dropdown {\n    margin-top: 25px;\n    &__title {\n        font-size: 18px;\n        font-weight: 500;\n        color: rgb(7,7,7);\n        cursor: pointer;\n        span {\n            font-size: 18px;\n            color:  #f00505;\n            font-weight: 600;\n        }\n    }\n\n    &__modal {\n        margin-top: 3px;\n        position: absolute;\n        width: 200px;\n        height: auto;\n        background-color: #ffffff;\n        border-radius: 10px;\n        border: 2px solid rgb(156, 153, 153);\n        cursor: pointer;\n\n        &-content {\n            padding-top: 10px;\n            padding-left: 9px;\n            float: left;\n        }\n\n        &-items {\n            list-style-type: none;\n        }\n\n        &-item {\n            font-size: 18px;\n            font-weight: 400;\n            color: rgb(7, 6, 6);\n            padding-bottom: 3px;\n            cursor: pointer; \n            &:hover {\n                font-weight: 600;\n            }\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
