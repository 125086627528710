// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.materials {
  margin-top: 15px;
  border-bottom: 2px solid rgba(58, 91, 204, 0.1490196078);
}
.materials__title {
  font-size: 21px;
  font-weight: 600;
  color: var(--e-global-color-primary);
  width: 340px;
}
@media (max-width: 460px) {
  .materials__title {
    font-size: 19px;
  }
}
.materials__content {
  padding-top: 15px;
  padding-bottom: 15px;
  display: flex;
}
.materials__content-item {
  width: 25px;
  height: 25px;
  border-radius: 50px;
  border: 2px solid #9b9999;
  cursor: pointer;
  margin-right: 5px;
}
.materials__content-item.active {
  border-color: #ff0000;
}
@media (max-width: 595px) {
  .materials__content-item {
    padding-right: 7px;
  }
}
@media (max-width: 385px) {
  .materials__content-item {
    width: 40px;
    height: 40px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Product/Materials/style.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,wDAAA;AACJ;AAAI;EACI,eAAA;EACA,gBAAA;EACA,oCAAA;EACA,YAAA;AAER;AADQ;EALJ;IAMQ,eAAA;EAIV;AACF;AADI;EACI,iBAAA;EACA,oBAAA;EACA,aAAA;AAGR;AAFQ;EACI,WAAA;EACA,YAAA;EAGA,mBAAA;EACA,yBAAA;EACA,eAAA;EACA,iBAAA;AAIZ;AAHY;EACI,qBAAA;AAKhB;AAHY;EAZJ;IAaQ,kBAAA;EAMd;AACF;AALY;EAfJ;IAgBQ,WAAA;IACA,YAAA;EAQd;AACF","sourcesContent":[".materials {\n    margin-top: 15px;\n    border-bottom: 2px solid #3A5BCC26;\n    &__title {\n        font-size: 21px;\n        font-weight: 600;\n        color: var(--e-global-color-primary);    \n        width: 340px; \n        @media(max-width: 460px) {\n            font-size: 19px;\n        }\n    }\n\n    &__content {\n        padding-top: 15px;\n        padding-bottom: 15px;\n        display: flex;\n        &-item {\n            width: 25px;\n            height: 25px;\n            -webkit-border-radius: 25px;\n            -moz-border-radius: 25px;\n            border-radius: 50px;\n            border: 2px solid #9b9999;\n            cursor: pointer;\n            margin-right: 5px;\n            &.active {\n                border-color: #ff0000;\n            }\n            @media(max-width: 595px) {\n                padding-right: 7px;\n            }\n            @media(max-width: 385px) {\n                width: 40px;\n                height: 40px;\n            }\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
