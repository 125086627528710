// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.message {
  margin-top: 90px;
  margin-bottom: 90px;
}
@media (max-width: 768px) {
  .message {
    margin-top: 70px;
    margin-bottom: 70px;
  }
}
@media (max-width: 460px) {
  .message {
    margin-top: 50px;
    margin-bottom: 50px;
  }
}
.message__title {
  font-size: 32px;
  font-weight: 500;
  color: var(--e-global-color-primary);
  text-align: center;
}
@media (max-width: 460px) {
  .message__title {
    font-size: 24px;
  }
}
.message__text {
  font-size: 25px;
  font-weight: 400;
  color: var(--e-global-color-primary);
  text-align: center;
  margin-top: 15px;
}
@media (max-width: 460px) {
  .message__text {
    font-size: 20px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/MessageOrder/style.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,mBAAA;AACJ;AAAI;EAHJ;IAIQ,gBAAA;IACA,mBAAA;EAGN;AACF;AAFI;EAPJ;IAQQ,gBAAA;IACA,mBAAA;EAKN;AACF;AAHI;EACI,eAAA;EACA,gBAAA;EACA,oCAAA;EACA,kBAAA;AAKR;AAJQ;EALJ;IAMQ,eAAA;EAOV;AACF;AAJI;EACI,eAAA;EACA,gBAAA;EACA,oCAAA;EACA,kBAAA;EACA,gBAAA;AAMR;AALQ;EANJ;IAOQ,eAAA;EAQV;AACF","sourcesContent":[".message {\n    margin-top: 90px;\n    margin-bottom: 90px;\n    @media(max-width: 768px) {\n        margin-top: 70px;\n        margin-bottom: 70px;\n    }\n    @media(max-width: 460px) {\n        margin-top: 50px;\n        margin-bottom: 50px;\n    }\n\n    &__title {\n        font-size: 32px;\n        font-weight: 500;\n        color: var(--e-global-color-primary);\n        text-align: center;\n        @media(max-width: 460px) {\n            font-size: 24px;\n        }\n    }\n\n    &__text {\n        font-size: 25px;\n        font-weight: 400;\n        color: var(--e-global-color-primary);\n        text-align: center;\n        margin-top: 15px;\n        @media(max-width: 460px) {\n            font-size: 20px;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
