// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.companyadvantages {
  margin-top: 75px;
}
@media (max-width: 460px) {
  .companyadvantages {
    margin-bottom: 35px;
  }
}
.companyadvantages__content {
  display: flex;
  justify-content: space-between;
}
@media (max-width: 460px) {
  .companyadvantages__content {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
.companyadvantages__card {
  width: 380px;
  height: auto;
}
@media (max-width: 1024px) {
  .companyadvantages__card {
    width: 300px;
  }
}
@media (max-width: 460px) {
  .companyadvantages__card {
    width: 100%;
  }
}
.companyadvantages__card-icon {
  display: block;
  text-align: center;
}
.companyadvantages__card-title {
  color: var(--e-global-color-primary);
  font-size: 24px;
  font-weight: bold;
  margin-top: 15px;
  text-align: center;
}
@media (max-width: 1024px) {
  .companyadvantages__card-title {
    font-size: 19px;
  }
}
.companyadvantages__card-text {
  margin-top: 15px;
  font-size: 17px;
  font-weight: normal;
  text-align: center;
  color: var(--e-global-color-primary);
}`, "",{"version":3,"sources":["webpack://./src/components/CompanyAdvantages/style.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;AACJ;AAAI;EAFJ;IAGQ,mBAAA;EAGN;AACF;AADI;EACI,aAAA;EACA,8BAAA;AAGR;AAFQ;EAHJ;IAIQ,sBAAA;IACA,uBAAA;IACA,mBAAA;EAKV;AACF;AAFI;EACI,YAAA;EACA,YAAA;AAIR;AAHQ;EAHJ;IAIQ,YAAA;EAMV;AACF;AALQ;EANJ;IAOQ,WAAA;EAQV;AACF;AANQ;EACI,cAAA;EACA,kBAAA;AAQZ;AALQ;EACI,oCAAA;EACA,eAAA;EACA,iBAAA;EACA,gBAAA;EACA,kBAAA;AAOZ;AANY;EANJ;IAOQ,eAAA;EASd;AACF;AANQ;EACI,gBAAA;EACA,eAAA;EACA,mBAAA;EACA,kBAAA;EACA,oCAAA;AAQZ","sourcesContent":[".companyadvantages {\n    margin-top: 75px;\n    @media(max-width: 460px) {\n        margin-bottom: 35px;\n    }\n\n    &__content {\n        display: flex;\n        justify-content: space-between;\n        @media(max-width: 460px) {\n            flex-direction: column; \n            justify-content: center;\n            align-items: center;\n        }\n    }\n\n    &__card {\n        width: 380px;\n        height: auto;\n        @media(max-width: 1024px) {\n            width: 300px;\n        }\n        @media(max-width: 460px) {\n            width: 100%;\n        }\n        \n        &-icon {\n            display: block;\n            text-align: center;\n        }\n\n        &-title {\n            color: var(--e-global-color-primary);\n            font-size: 24px;\n            font-weight: bold;\n            margin-top: 15px;\n            text-align: center;\n            @media(max-width: 1024px) {\n                font-size: 19px;\n            }\n        }\n\n        &-text {\n            margin-top: 15px;\n            font-size: 17px;\n            font-weight: normal;\n            text-align: center;\n            color: var(--e-global-color-primary);\n           \n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
